import styled from "@emotion/styled";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../hooks";
import { LocaleContext } from "./Layout";

const StyledFooter = styled.footer`
  margin-top: 0rem;
  max-width: 100%;
  padding: 0.5rem;
  color: #c78866;
  text-align: center;
  min-height: 2rem;
  border-top: 1px solid #c78866;
  font-size: 1.4rem;
`;

const Footer = () => {
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [open, setOpen] = useState(false);

  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  });

  return (
    <StyledFooter>
      {/*       <div ref={node} style={{ display: "block", zIndex: 999 }}>
        {width && width < 899 && <Burger open={open} setOpen={setOpen} />}
        {width && width < 899 && <MobileMenu open={open} setOpen={setOpen} />}
      </div> */}

      {i18n && i18n.path === "en" ? (
        <a href="https://oriacondominiums.com/en/contact/" rel="noreferrer">
          Register now!
        </a>
      ) : (
        <a href="https://oriacondominiums.com/contact/" rel="noreferrer">
          Inscrivez-vous!
        </a>
      )}
    </StyledFooter>
  );
};

export default Footer;
